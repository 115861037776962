<template>
  <div class="page-container stock-outputs-page">
    <portal to="page-name">Saídas de produtos e serviços</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Saídas de produtos e serviços</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Nova Saída
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Saídas</p>
            <p class="empty-subtitle">
              Nenhuma saída encontrada. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th>Data</th>
              <th>Status</th>
              <th>Origem</th>
              <th>Destino</th>
              <th class="text-right">Valor total</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td width="150px">
                <router-link :to="editLink(item)">
                  {{ item.date | date('DD/MM/YYYY HH:mm') }}
                </router-link>
              </td>
              <td :class="item.status === 'open' ? 'text-warning text-bold' : '' ">
                {{ status.getName(item.status) }}
              </td>
              <td>{{ item.originPlace.name }}</td>
              <td>
                <span v-if="item.patient">{{ item.patient.name }}</span>
                <span v-else-if="item.destinationPlace">{{ item.destinationPlace.name }}</span>
                <span v-else>Saída por requisição</span>
              </td>
              <td class="text-right">{{ item.total | currency }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip mr-1"
                        @click="edit(item)" data-tooltip="Editar">
                  <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                </button>
                <button class="btn btn-sm btn-error btn-icon btn-action"
                        :class="{loading: deleting}"
                        @click="remove(item.id, i)"
                        :disabled="deleting || item.status !== 'open'">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <span>Total de registros:
          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as status from 'src/data/stock-statuses';
import { DELETE_OUTPUT } from '@/data/actions/modules/stock';

export default {
  data() {
    return {
      debounceTimeout: null,
      loading: false,
      deleting: false,
      status,
      filter: {
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  computed: {
    canAccessRemoveOutput() {
      if (DELETE_OUTPUT) {
        return this.$can(DELETE_OUTPUT);
      }
      return true;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get('/stock-outputs', { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    create() {
      this.$router.push('/stock/outputs/create');
    },
    edit(item) {
      this.$router.push(`/stock/outputs/${item.id}/edit`);
    },
    remove(id, i) {
      if (!this.canAccessRemoveOutput) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.$http
                .delete(`/stock-outputs/${id}`)
                .then(() => {
                  this.data.items.splice(i, 1);
                })
                .catch(() => {
                })
                .then(() => {
                  this.deleting = false;
                  close();
                });
            },
          },
        ],
      });
    },
    editLink(item) {
      return `/stock/outputs/${item.id}/edit`;
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-outputs {
}
</style>
