<template>
  <div class="page-container stock-output-page">
    <portal to="page-name">Saída de produtos e serviços</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Saída de produtos e serviços</div>
      </div>
      <div class="card-body">
        <div v-if="finished">
          <small class="text-info">* Esta saída já foi finalizada</small>
          <div class="card-detail">
            <div class="card-detail-title">Dados da saída</div>
            <div class="card-detail-data">
              <div class="columns">
                <div class="column col-12 form-group">
                  <label class="form-label text-bold">Data</label>
                  {{ form.date | date('DD/MM/YYYY HH:mm') }}
                </div>
                <div class="column col-12 form-group" v-if="form.notes">
                  <label class="form-label text-bold">Observações</label>
                  {{ form.notes }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-detail">
            <div class="card-detail-title">Produtos</div>
            <div class="card-detail-data">
              <table class="table">
                <thead>
                <tr>
                  <th class="hide-sm" width="40px">#</th>
                  <th>Produto</th>
                  <th class="text-center">Unidade</th>
                  <th>Lote</th>
                  <th>Validade</th>
                  <th class="text-center">Quantidade</th>
                  <th class="text-right">Valor Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in form.products" :key="i">
                  <td class="hide-sm">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ measure.getName(item.outputUnit) }}</td>
                  <td>{{ item.lot ? item.lot.name : '' }}</td>
                  <td>{{ item.lot ? item.lot.validity : '' | date }}</td>
                  <td class="text-center" width="100px">
                    {{ item.quantity | number(3) }}
                  </td>
                  <td class="text-right">{{ item.total | currency }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="columns form-group">
            <div class="column col-3 col-md-12 col-sm-12 form-group">
              <label class="form-label">Data da movimentação</label>
              <dx-input-date
                id="date" name="date" class="form-input"
                v-model="form.date" readonly/>
            </div>
            <div class="column col-9 form-group hide-md hide-sm" />
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.operationTypeId.$error}">
              <label class="form-label">Tipo de operação</label>
              <select id="operation-type" class="form-select"
                      v-model="form.operationTypeId"
                      @blur="$v.form.operationTypeId.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="item in operationTypes"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.operationTypeId.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.operationTypeId.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.originPlaceId.$error}">
              <label class="form-label">Local de origem</label>
              <select id="origin-place" class="form-select"
                      v-model="form.originPlaceId"
                      :disabled="form.products.length > 0"
                      @blur="$v.form.originPlaceId.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="item in places"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.originPlaceId.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.originPlaceId.required">Campo obrigatório</div>
              </template>
            </div>
          </div>
          <div class="divider" />
          <div class="columns">
            <div class="column col-12 form-group">
              <label class="form-switch mb-1">
                <input type="checkbox" v-model="showPatient" @click="openPatient">
                <i class="form-icon"></i>Realizar a saída para um paciente
              </label>
              <div :class="{'has-error': $v.patient.item.id.$error}">
                <st-autocomplete
                  id="patient"
                  ref="patientAutocomplete"
                  class="mb-2"
                  v-if="showPatient"
                  v-model="patient.item"
                  :items="patient.items"
                  :auto-select-one-item="false"
                  :disabled="!!patient.item || !showPatient"
                  :loading="patient.loading"
                  :get-label="getPatientLabel"
                  placeholder="Nome, data de nascimento ou CPF"
                  @item-selected="selectPatientItem"
                  @blur="$v.patient.item.id.$touch()"
                  @update-items="updatePatientItems">
                  <a slot-scope="scope" class="menu-item-tile">
                    <figure class="avatar">
                      <fa-icon :icon="['fal', 'heartbeat']"></fa-icon>
                    </figure>
                    <div class="name"
                         v-html="scope.highlight(scope.item.name)"></div>
                  </a>
                  <button v-if="patient.item && patient.item.name"
                          slot="action" @click="removePatient"
                          class="btn btn-icon btn-action btn-gray input-group-btn">
                    <fa-icon :icon="['fal', 'times']"></fa-icon>
                  </button>
                  <button tabindex="-1" slot="action" v-else
                          class="btn btn-icon btn-action input-group-btn btn-neutral">
                    <fa-icon :icon="['fal', 'search']"></fa-icon>
                  </button>
                </st-autocomplete>
                <template v-if="$v.patient.item.id.$error">
                  <div class="form-input-hint"
                       v-if="!$v.patient.item.id.required">Campo obrigatório</div>
                </template>
              </div>
            </div>
            <div class="column col-12 form-group">
              <label class="form-switch mb-1">
                <input type="checkbox" v-model="showPlace" @click="openPlace">
                <i class="form-icon"></i>Transferir para um local
              </label>
              <template v-if="showPlace">
                <div :class="{'has-error': $v.form.destinationPlaceId.$error}">
                  <select id="destination-place" class="form-select mb-2"
                          v-model="form.destinationPlaceId"
                          @blur="$v.form.destinationPlaceId.$touch()">
                    <option value="">[Selecione]</option>
                    <option v-for="item in places"
                            :value="item.id" :key="item.id">{{ item.name }}</option>
                  </select>
                  <template v-if="$v.form.destinationPlaceId.$error">
                    <div class="form-input-hint"
                         v-if="!$v.form.destinationPlaceId.required">Campo obrigatório</div>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <div class="divider" />
          <div class="form-group">
            <label class="form-label">Produto</label>
            <st-autocomplete
              id="product"
              ref="productAutocomplete"
              v-model="product.item"
              :items="product.items"
              :disabled="!form.originPlaceId"
              :auto-select-one-item="false"
              :get-label="getProductLabel"
              :loading="product.loading"
              placeholder="Informe o nome, código ou lote"
              @item-selected="selectProductItem"
              @focus="product.items = []"
              @update-items="updateProductItems">
              <a slot-scope="scope" class="menu-item-tile">
                <figure class="avatar">
                  <fa-icon :icon="['fal', 'box']"></fa-icon>
                </figure>
                <div class="name"
                     v-html="scope.highlight(scope.item.name)"></div>
              </a>
              <button tabindex="-1" slot="action"
                      @mouseup="openProductList"
                      :disabled="!form.originPlaceId"
                      class="btn btn-icon btn-action input-group-btn btn-neutral">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
            </st-autocomplete>
          </div>
          <div class="empty" v-if="form.products.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Produtos</p>
            <p class="empty-subtitle">
              Nenhum produto adicionado. Utilize o campo acima para realizar a busca.
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Produto</th>
              <th class="text-center">Unidade</th>
              <th>Lote</th>
              <th>Validade</th>
              <th class="text-center">Quantidade</th>
              <th class="text-right">Valor Total</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in form.products" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>{{ item.name }}</td>
              <td class="text-center">{{ measure.getName(item.outputUnit) }}</td>
              <td>{{ item.lot ? item.lot.name : 'Sem lote' }}</td>
              <td>
                <span v-if="item.lot">{{ item.lot.validity | date }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-center" width="100px">
                <div :class="{'has-error': item.quantity > item.limit}">
                  <dx-input-number v-model="item.quantity"
                                   class="form-input text-center"
                                   @change="updateTotal(item)"
                                   :precision="3" maxlength="11"
                  />
                </div>
              </td>
              <td class="text-right">{{ item.total | currency }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        @click="removeItem(item.id, i)">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <span class="float-right text-bold mt-2">
            Total: {{ total | currency }}
          </span>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="!finished"
                @click="save(false)"
                :disabled="finishing || saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn btn-warning mr-1"
                v-if="!finished"
                @click="save(true)"
                :disabled="finishing || saving"
                :class="{loading: finishing}">
          Finalizar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
<!--    <modals-product-list-->
<!--      v-if="modalProductList.show"-->
<!--      :show="modalProductList.show"-->
<!--      :placeId="form.originPlaceId"-->
<!--      :has-balance="true"-->
<!--      @productData="selectProduct"-->
<!--      @kitData="setKit"-->
<!--      @close="modalProductList.show = false"-->
<!--    />-->
    <dx-modal title="Lotes do produto" :value="modalProductLot.show"
              @input="modalProductLot.show = false" id="modal-product-lots" size="sm">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th>Lote</th>
          <th class="text-center">Validade</th>
          <th class="text-center">Quantidade</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalProductLot.items" :key="i"
            class="c-hand"
            @click="selectProductLot(item)">
          <td>{{ item.lot ? item.lot.name : 'Sem lote' }}</td>
          <td class="text-center" width="120px">
            <span v-if="item.lot">{{ item.lot.validity | date }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center text-bold" width="120px"
              :class="item.quantity <= 0 ? 'text-error' : 'text-primary'">
            {{ item.quantity }}
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="modalProductLot.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import moment from 'moment';
import * as measure from 'src/data/measure-units';
import { FINISH_OUTPUT } from '@/data/actions/modules/stock';
import formMixin from '../../../mixins/form';
// import ModalProductList from '../../product/components/List.vue';

export default {
  components: {
    // ModalProductList,
  },
  mixins: [formMixin],
  data() {
    return {
      measure,
      isNew: false,
      loading: false,
      saving: false,
      finishing: false,
      showPatient: false,
      showPlace: false,
      operationTypes: [],
      patients: [],
      places: [],
      patient: {
        loading: false,
        item: null,
        items: [],
      },
      product: {
        item: null,
        loading: false,
        items: [],
      },
      modalProductLot: {
        show: false,
        items: [],
      },
      modalProductList: {
        show: false,
      },
      form: this.blankForm(),
    };
  },
  computed: {
    finished() {
      return this.form.status === 'finished';
    },
    total() {
      return this.form.products.reduce((a, b) => a + Number(b.total), 0);
    },
    canAccessFinishOutput() {
      if (FINISH_OUTPUT) {
        return this.$can(FINISH_OUTPUT);
      }
      return true;
    },
  },
  validations() {
    const rules = {
      form: {
        date: { required },
        operationTypeId: { required },
        originPlaceId: { required },
        destinationPlaceId: {},
      },
      patient: {
        item: {
          id: {},
        },
      },
    };

    if (this.showPlace) {
      rules.form.destinationPlaceId = { required };
    }

    if (this.showPatient) {
      rules.patient.item.id = { required };
    }

    return rules;
  },
  async mounted() {
    this.loadPlaces();
    this.loadOperationTypes();
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      return this.$http
        .get(`/stock-outputs/${this.form.id}`)
        .then(({ data }) => {
          this.form = {
            id: data.id,
            date: data.date,
            status: data.status,
            operationTypeId: data.operationType.id,
            originPlaceId: data.originPlace.id,
            destinationPlaceId: data.destinationPlace
              ? data.destinationPlace.id
              : '',
            notes: data.notes,
            products: data.products,
          };
          if (data.patient && data.patient.id) {
            this.showPatient = true;
            this.form.patientId = data.patient.id;
            this.patient.item = data.patient;
          }
          this.showPlace = !!data.destinationPlace;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadPlaces() {
      this.places = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    loadOperationTypes() {
      this.operationTypes = [];

      const params = {
        active: true,
        type: 'output',
        limit: 0,
      };

      return this.$http
        .get('/operation-types', { params })
        .then(({ data }) => {
          this.operationTypes = data.items;
        })
        .catch(() => {});
    },
    openPatient() {
      this.form.destinationPlaceId = '';
      this.showPlace = false;
    },
    openPlace() {
      if (this.showPatient) {
        this.removePatient();
      }
      this.showPatient = false;
    },
    async save(finished = false) {
      if (finished && !this.canAccessFinishOutput) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$v.form.$touch();
      this.$v.patient.$touch();
      if (this.$v.form.$error || this.$v.patient.$error || this.saving || this.finishing) {
        return;
      }

      if (this.form.products.length === 0) {
        this.$toast.show('Adicione o(s) produto(s)', { type: 'error' });
        return;
      }

      if (finished) {
        this.finishing = true;
      } else {
        this.saving = true;
      }

      let notBalance = false;
      this.form.products.forEach(({ quantity, limit }) => {
        if (quantity > limit) {
          notBalance = true;
        }
      });

      if (notBalance) {
        this.saving = false;
        this.finishing = false;
        this.$toast.show('Existe(m) produto(s) com saldo insuficiente!', { type: 'error' });
        return;
      }

      const output = this.clone(this.form);
      output.status = finished ? 'finished' : 'open';

      if (!this.showPlace) {
        delete output.destinationPlaceId;
      }

      if (!this.showPatient) {
        delete output.patientId;
      }

      const request = this.isNew
        ? this.$http.post('/stock-outputs', output)
        : this.$http.put(`/stock-outputs/${this.form.id}`, output);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/outputs/${result.id}/edit`);
            this.isNew = false;
            this.form.id = result.id;
          }
          this.form.status = finished ? 'finished' : 'open';
          this.$toast.show('Registro salvo');
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error' });
          }
        })
        .then(() => {
          this.saving = false;
          this.finishing = false;
        });
    },
    selectPatientItem(item) {
      this.form.patientId = item.id;
    },
    getPatientLabel(item) {
      return item ? item.name : '';
    },
    updatePatientItems(text) {
      const value = text.trim();

      const params = {
        search: value,
        limit: 10,
      };

      if (value.length) {
        this.patient.loading = true;
        this.$http
          .get('/patients/search', { params })
          .then(({ data }) => {
            this.patient.items = data.items;
          })
          .catch(() => {})
          .finally(() => {
            this.patient.loading = false;
          });
      } else {
        this.patient.items = [];
      }
    },
    removePatient() {
      this.$refs.patientAutocomplete.searchText = null;
      this.form.patientId = '';
      this.patient.items = [];
      this.patient.item = null;
    },
    getProductLabel(item) {
      return item ? item.name : '';
    },
    updateProductItems(text) {
      const value = text.trim();

      if (value && value.length === 8) {
        // kits
      }

      if (value.length) {
        this.product.loading = true;
        const params = {
          types: ['material', 'medicine', 'others'],
          search: value,
          limit: 10,
        };
        this.$http
          .get('/procedures', { params })
          .then(({ data }) => {
            if (data.items && data.items[0].selected) {
              this.selectProductItem(data.items[0]);
            } else {
              this.product.items = data.items;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.product.loading = false;
          });
      } else {
        this.product.items = [];
      }
    },
    async selectProductItem(item) {
      const params = {
        placeId: this.form.originPlaceId,
        productId: item.id,
        hasBalance: true,
      };
      await this.findProductBalance(params)
        .then(({ items }) => {
          if (items.length === 0) {
            this.$toast.error('Produto sem saldo em estoque!');
          } else if (items.length === 1) {
            this.setProduct(items[0]);
          } else if (items.length > 1) {
            this.modalProductLot.items = items;
            this.modalProductLot.show = true;
          }
        });

      this.$refs.productAutocomplete.searchText = null;
    },
    removeItem(id, i) {
      this.form.products.splice(i, 1);
    },
    openProductList() {
      this.modalProductList.show = true;
    },
    findProductBalance(params) {
      return this.$http.get('/stock-balances', { params })
        .then(({ data }) => data)
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    selectProduct(data) {
      if (data.quantity <= 0) {
        this.$toast.show('Este produto está sem saldo em estoque!', { type: 'error' });
        return;
      }
      this.setProduct(data);
      this.modalProductList.show = false;
    },
    setProduct(data) {
      const found = this.form.products
        .find((item) => {
          if (data.lot) {
            return item.id === data.id
              && item.lot
              && item.lot.id === data.lot.id;
          }
          return item.id === data.id && !item.lot;
        });

      if (found) {
        found.quantity += 1;
        found.total = found.cost * found.quantity;
      } else {
        this.form.products.push({
          id: data.product.id,
          name: data.product.name,
          outputUnit: data.product.outputUnit,
          lot: data.lot,
          quantity: 1,
          cost: data.cost,
          total: data.cost,
          limit: data.quantity,
        });
      }
    },
    setKit(data) {
      data.products.forEach((product) => {
        const found = this.form.products
          .find((productItem) => {
            if (productItem.manageLot) {
              return productItem.id === product.id
                && productItem.lot.id === product.lot.id;
            }
            return productItem.id === product.id;
          });

        if (found) {
          found.quantity += product.quantity;
          found.total = found.cost * found.quantity;
        } else {
          this.form.products.push({
            id: product.id,
            name: product.name,
            outputUnit: product.outputUnit,
            lot: product.lot,
            quantity: product.quantity,
            cost: product.cost,
            total: product.cost,
            limit: product.quantity,
          });
        }
      });
      this.modalProductList.show = false;
    },
    selectProductLot(data) {
      if (data.quantity <= 0) {
        this.$toast.show('Este lote está sem saldo em estoque!', { type: 'error' });
        return;
      }
      this.setProduct(data);
      this.modalProductLot.show = false;
    },
    updateTotal(item) {
      if (!item.quantity || item.quantity <= 0) {
        item.quantity = 1;
      }
      item.total = item.cost * item.quantity;
    },
    blankForm() {
      return {
        id: '',
        date: moment().format('YYYY-MM-DD[T]HH:mm'),
        status: '',
        operationTypeId: '',
        originPlaceId: '',
        destinationPlaceId: '',
        patientId: '',
        notes: '',
        products: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-output-page {
  .card-detail {
    background-color: lighten($gray-color-light, 5%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-weight: bold;
      margin-bottom: $layout-spacing;
    }
  }
  .form-checkbox {
    line-height: 1rem;
    margin: 0;
    min-height: 0;
    i {
      top: 0.15rem;
    }
  }
}
</style>
